/// <reference types="@angular/localize" />

import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { APP_ROUTES } from './app/app.routes';
import { ErrorHandler, InjectionToken, isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from './environments/environment';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { provideAnimations } from '@angular/platform-browser/animations';
import { GlobalErrorHandler } from './app/services/global-error-handler';
import { provideHttpClient } from '@angular/common/http';
import { TranslocoHttpLoader } from './app/services/transloco-loader';
import { provideTransloco } from '@ngneat/transloco';
import Rollbar from 'rollbar';
import { VERSION } from './app/version';
import { inMemoryPersistence, setPersistence } from 'firebase/auth';

(window as any).VERSION = VERSION;
(window as any).MAINTENANCE = false;

export const RollbarService = new InjectionToken<Rollbar>('rollbar');


bootstrapApplication(AppComponent, {
  providers: [
    provideAnimations(),
    provideRouter(APP_ROUTES, withInMemoryScrolling( { anchorScrolling: 'enabled' })),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      // As httpOnly cookies are to be used, do not persist any state client side.
      // Note setPersistence is imported from 'firebase/auth' https://stackoverflow.com/questions/69292778/angularfire-auth-persistence-cannot-be-invoked-without-new
      const auth = getAuth();
      setPersistence(auth, inMemoryPersistence);
      return auth;
    }),
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    provideHttpClient(),
    provideTransloco({
      config: {
        availableLangs: ['en', 'bn'],
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader
    }),
    // https://docs.rollbar.com/docs/angular
    {
      provide: RollbarService,
      useFactory: () => new Rollbar({
        accessToken: environment.rollbarToken,
        enabled: environment.rollbarToken !== undefined,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: environment.production ? 'production': 'other',
          client: {
            javascript: {
              source_map_enabled: true,
              code_version: VERSION,
              guess_uncaught_frames: true
            }
          }
        }
      })
    },
  ],
});
