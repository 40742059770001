import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitialLoaderService } from './initial-loader.service';

@Component({
  selector: 'buildswell-initial-loader',
  standalone: true,
  imports: [ CommonModule ],
  templateUrl: './initial-loader.component.html',
  styleUrls: ['./initial-loader.component.scss']
})
export class InitialLoaderComponent {
  constructor(protected loaderService: InitialLoaderService) {
  }
}
