import { inject } from '@angular/core';
import { map, take, tap } from 'rxjs';
import { StateService } from '../services/state.service';
import { AsyncCanActivateFn } from './ordered-async.guard';
import { ErrorOverlayService } from '../error-overlay/error-overlay.service';

export const or = (...features: string[]) => RegExp(features.map(feature => `^${feature}$`).join('|'));

export const hasFeature: (feature: string|RegExp) => AsyncCanActivateFn = feature => {
  return () => {
    const state = inject(StateService);
    const errorOverlay = inject(ErrorOverlayService);

    return state.select(state => state.user)
      .pipe(
        take(1),
        map(user => {
          if (typeof feature === 'string') {
            return user!.features.includes(feature)
          }

          return user!.features.some(f => f.match(feature));
        }),
        tap(hasFeature => {
          if (!hasFeature) {
            errorOverlay.show({ title: `User doesn't have access`});
          }
        })
      );
  }
};
