import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { filter, map, mergeMap, of, take } from 'rxjs';
import { StateService } from '../services/state.service';
import { Auth, user } from '@angular/fire/auth';
import { AsyncCanActivateFn } from './ordered-async.guard';


export const isSignedIn: AsyncCanActivateFn = () => {
  const state = inject(StateService);
  const auth = inject(Auth);

  const isSignedInBySso = state.select(state => state.user)
    .pipe(
      filter(user => user !== undefined), // User is set after SSO is done
      map(ssoState => !!ssoState)
    );

  const isSignedInByFirebase = user(auth)
    .pipe(
      map(user => !!user)
    );

  return isSignedInBySso
    .pipe(
      mergeMap(ssoState => ssoState ? isSignedInByFirebase : of(false)),
      take(1)
    );
};


export const isNotSignedIn: CanActivateFn = () => {
  const state = inject(StateService);
  const router = inject(Router);

  return state.select(state => state.user)
    .pipe(
      filter(user => user !== undefined),
      map(user => {
        if (user === null) {
          return true;
        }
        return router.createUrlTree(['/']);
      }),
      take(1)
    );
};
