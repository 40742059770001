<div class="maintenance-mode">

  <div class="connectors top mt-3">
    <svg viewBox="0 0 220 50" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-secondary mb-2">
      <path id="connector-01" d="M 22000 18 H 50 V 0 H 0 v 50 h 50 V 32 h 21950 z M 36 36 H 14 V 14 h 22 V 36 z"></path>
    </svg>
    <svg viewBox="0 0 220 50" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-tertiary">
      <path d="M 22000 18 H 50 V 0 H 0 v 50 h 50 V 32 h 21950 z M 36 36 H 14 V 14 h 22 V 36 z"></path>
    </svg>
  </div>

  <div class="p-3 d-flex flex-column text-center" *ngIf="message">
      <fa-icon [icon]="message.icon" [size]="'4x'" class="text-blue-02 slideDown"></fa-icon>
      <h1 class="text-blue-01 slideDown">{{message.text}}</h1>
      <p class="text-grey-dark">Please try again later ...</p>
  </div>

  <div class="connectors bottom mb-3">
    <svg viewBox="0 0 220 50" xmlns="http://www.w3.org/2000/svg" class="fill-secondary mb-2">
      <use href="#connector-01"></use>
    </svg>
    <svg viewBox="0 0 220 50" xmlns="http://www.w3.org/2000/svg" class="fill-tertiary">
      <use href="#connector-01"></use>
    </svg>
  </div>

</div>
