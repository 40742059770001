import { CanDeactivateFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExitConfirmationModalComponent } from '../engage/exit-confirmation-modal/exit-confirmation-modal.component';
import { EngageComponent } from '../engage/engage.component';

export const canDisengage: CanDeactivateFn<EngageComponent> = (component: EngageComponent): Observable<boolean> => {
  if (component.canDisengage()) {
    return of(true);
  }

  const ngbModal = inject(NgbModal);
  const modal = ngbModal.open(ExitConfirmationModalComponent, { centered: true, backdrop: 'static' });

  return modal.closed;
}
