import { filter, from, mergeMap, Observable, of, skipWhile, take, tap } from 'rxjs';
import { Auth, getRedirectResult, idToken, signInWithCustomToken } from '@angular/fire/auth';
import { TrpcService } from './trpc.service';
import { Injectable } from '@angular/core';
import { User } from '../types/types';

@Injectable({
  providedIn: 'root'
})
export class SsoService {
  constructor(private auth: Auth,
              private trpc: TrpcService) {
  }

  authenticate(): Observable<User> {
    return this.maybeHandleSignInWithRedirect()
      .pipe(
        // If getting token fails, backend returns 401 and  trpc service will send user to /sign-in
        // But still error is thrown and it will be caught by outer catchError
        mergeMap(() => from(this.trpc.client.sso.getToken.query())),
        mergeMap(token => from(signInWithCustomToken(this.auth, token))),
        mergeMap(() => this.trpc.client.user.get.query()),
        tap(() => this.monitorFirebaseAuthState())
      );
  }

  // If it's sign in with redirect, it never returns
  private maybeHandleSignInWithRedirect(): Observable<unknown> {

    return from(getRedirectResult(this.auth))
      .pipe(
        mergeMap(user => user ? this.signedIn(): of(null))
      );
  }

  private monitorFirebaseAuthState() {
    // idToken emits when:
    // 1. Pushed logout button
    // 2. refreshing token failed because sso.signOut is called on different device
    // Note 1: not authState but idToken method is used since unlike idToken/user, authState is not invoked when token refreshes
    // Note 2: in order for refresh to happen, you need to actively read user/id token somewhere (we do it in route guard)
    idToken(this.auth)
      .pipe(
        filter(token => !token),
        tap(() => location.reload())
      )
      .subscribe();
  }

  // Must be called after a user is successfully signed in on sign in page
  signedIn(): Observable<never> {
    return idToken(this.auth)
      .pipe(
        take(1),
        mergeMap(token => this.trpc.client.sso.signIn.mutate(token!)),
        tap(() => location.reload()), // Refresh page to re-initialize app
        skipWhile(() => true) // Do not return
      );
  }
}
