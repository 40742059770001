<div class="sticky-top toast-wrapper d-flex justify-content-center">
  <ng-container *ngFor="let message of notification.messages">
    <div class="toast show mt-1" @fadeInOut>
      <div class="toast-body px-4 d-flex">
        <fa-icon class="me-2" [icon]="faCircleExclamation"></fa-icon>
        <div>
          {{ message.message }}
        </div>
        <fa-icon class="ms-auto clickable text-secondary" [icon]="faXmark" (click)="notification.remove(message)"></fa-icon>
      </div>
    </div>
  </ng-container>
</div>
