<ng-container *ngFor="let toast of toastService.toasts; trackBy trackById">
  <div class="mb-2" @fadeSlideInInOutOut>
    <div class="toast show" role="alert">
      <div class="toast-header">
        <div [ngClass]="{ 'text-info': toast.level === 'info', 'text-warning': toast.level === 'warn' }">
          <fa-icon [icon]="faSquare"></fa-icon>
        </div>
        <strong class="ms-2 me-auto">{{ toast.title }}</strong>
        <button type="button" class="btn-close" (click)="toastService.remove(toast)"></button>
      </div>
      <div class="toast-body">
        {{ toast.message }}
      </div>
    </div>
  </div>
</ng-container>
