import { inject } from '@angular/core';
import { map, take } from 'rxjs';
import { StateService } from '../services/state.service';
import { AsyncCanActivateFn } from './ordered-async.guard';


export const homeRedirector: AsyncCanActivateFn = () => {
  const state = inject(StateService);

  return state.select(state => state.user)
    .pipe(
      take(1),
      map(user => {
        if (user!.homeUrl) {
          window.location.href = user!.homeUrl;
          return false;
        }

        return true;
      })
    );
};
