import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'upskill-exit-confirmation-modal',
  standalone: true,
  imports: [CommonModule, FaIconComponent],
  templateUrl: './exit-confirmation-modal.component.html',
  styleUrls: ['./exit-confirmation-modal.component.scss']
})
export class ExitConfirmationModalComponent {
  constructor(protected activeModal: NgbActiveModal) {
  }

  cancel(): void {
    this.activeModal.close(false);
  }

  confirm(): void {
    this.activeModal.close(true);
  }
}
