<upskill-work-in-progress *ngIf="inMaintenance"/>

<ng-container *ngIf="!inMaintenance">
  <upskill-preview-indicator/>
  <upskill-top-bar-notification/>

  <buildswell-initial-loader/>
  <buildswell-error-overlay/>
  <buildswell-loading-overlay/>

  <buildswell-toasts/>

  <router-outlet/>
</ng-container>
