import { Component } from '@angular/core';
import { filter, take, tap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { StateService } from '../services/state.service';
import { ToastService } from '../shared/toast/toast.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'upskill-preview-indicator',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './preview-indicator.component.html',
  styleUrls: ['./preview-indicator.component.scss']
})
export class PreviewIndicatorComponent {
  isShown = false;

  constructor(private router: Router,
              private state: StateService,
              private toast: ToastService) {

    this.router.events
      .pipe(
        // We don't want to enable this for preview
        filter(event => event instanceof NavigationEnd),
        take(1),
        filter(event => !(event as NavigationEnd).url.startsWith('/preview')),
        tap(() => this.monitor())
      )
      .subscribe();
  }

  private monitor(): void {
    this.state.select(state => state.preview)
      .pipe(
        tap(preview => {
          if (preview !== undefined && this.isShown !== preview) {
            this.toast.info('Preview mode', `Preview mode ${preview ? 'on' : 'off'}`);
          }
        }),
        tap(preview => this.isShown = preview === true),
      )
      .subscribe();
  }
}
