<div class="loader-centered" *ngIf="loaderService.isShown">
  <svg id="lw-loader" width="82" height="104" viewBox="0 0 82 104" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path class="outline" fill-rule="evenodd" clip-rule="evenodd"
          d="M0 0V30.5H11V92.5H52.5V104H81.5V73.5H52.5V85H18V30.5H29V0H0ZM8 8V22.5H21V8H8ZM60.5 81.5V96H73.5V81.5H60.5Z"/>
  </svg>


  <svg id="lw-loader-brand" width="399" height="38" viewBox="0 0 399 38" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path class="brand-l1"
          d="M0.699219 36.5673V1.09229H5.88321V31.3843H27.4722V36.5683H0.699219V36.5673Z"/>
    <path class="brand-e1"
          d="M36.4463 36.5673V1.09229H65.6443V6.32329H41.6313V15.5963H53.7083V20.7323H41.6313V31.3833H66.5493V36.5673H36.4463Z"/>
    <path class="brand-a"
          d="M102.497 36.5671L97.4094 25.0111H81.4774L76.2004 36.5671H70.5874L86.7094 1.14014H92.4154L108.298 36.5671H102.497ZM89.4674 7.41714L83.8554 19.7801H95.0774L89.4674 7.41714Z"/>
    <path class="brand-r"
          d="M140.396 21.6833L147.767 36.5673H141.917L134.783 21.8743H121.565V36.5673H116.38V1.09229H137.4C143.866 1.09229 148.575 4.80229 148.575 11.5533C148.575 16.9273 145.531 20.4473 140.396 21.6833ZM137.4 6.22929H121.565V16.6903H137.4C140.919 16.6903 143.439 15.0253 143.439 11.2693C143.438 7.56029 140.775 6.22929 137.4 6.22929Z"/>
    <path class="brand-n"
          d="M186.283 36.5673L164.884 9.55828V36.5673H159.796V1.09229H164.838L186.189 28.1973V1.09229H191.42V36.5673H186.283Z"/>
    <path class="brand-s"
          d="M218.332 37.3761C211.103 37.3761 205.682 35.2831 200.594 30.0991L204.21 26.6281C208.774 30.8611 212.865 32.1441 219.046 32.1441C222.993 32.1441 230.03 30.9081 230.03 25.7731C230.03 24.0121 229.128 22.6811 227.653 21.7311C225.798 20.5411 224.04 20.4471 218.809 20.3511L213.481 20.2551C207.965 20.1611 202.926 17.2601 202.926 11.1731C202.926 5.98911 207.252 2.51811 211.769 1.04411C213.767 0.426113 215.86 0.141113 217.905 0.141113C223.229 0.141113 228.366 1.75812 232.646 4.99112L229.744 9.12912C225.895 6.75112 223.183 5.42011 218.569 5.42011C215.099 5.42011 208.156 6.51411 208.156 11.1261C208.156 14.5971 211.341 15.2171 214.147 15.2171H219.425C223.371 15.2171 226.607 15.2641 230.126 17.3551C233.311 19.2591 235.117 22.4921 235.117 26.2001C235.117 34.8081 225.275 37.3761 218.332 37.3761Z"/>
    <path class="brand-w"
          d="M276.583 36.5673H271.256L264.027 11.4593L257.039 36.5673H251.426L240.822 1.09229H246.15L254.137 27.3903L261.414 1.09229H266.453L273.922 27.3903L281.766 1.09229H287.188L276.583 36.5673Z"/>
    <path class="brand-e2"
          d="M295.555 36.5673V1.09229H324.754V6.32329H300.736V15.5963H312.816V20.7323H300.736V31.3833H325.655V36.5673H295.555Z"/>
    <path class="brand-l2"
          d="M336.306 36.5673V1.09229H341.49V31.3843H363.079V36.5683H336.306V36.5673Z"/>
    <path class="brand-l3"
          d="M371.541 36.5673V1.09229H376.724V31.3843H398.314V36.5683H371.541V36.5673Z"/>
  </svg>
</div>
