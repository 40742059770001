import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { ErrorOverlayService } from '../error-overlay/error-overlay.service';
import Rollbar from 'rollbar';
import { RollbarService } from '../../main';
import { TRPCClientError } from '@trpc/client';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private errorOverlay: ErrorOverlayService,
              @Inject(RollbarService) private rollbar: Rollbar) {
  }

  handleError(error: any): void {
    if (error instanceof TRPCClientError && error.data?.httpStatus === 401) {
      return;
    }

    const loadErrorMessage = /Failed to fetch dynamically imported module/;
    if (loadErrorMessage.test(error.message)) {
      this.errorOverlay.show({ title: 'Failed to load resources' });
      return;
    }

    console.error(error);
    this.rollbar.error(error);
  }
}
