import { Component, HostBinding } from '@angular/core';

import { Toast, ToastService } from './toast.service';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSquare } from '@fortawesome/pro-solid-svg-icons';
import { fadeSlideInInOutOut } from '../animations';

@Component({
  selector: 'buildswell-toasts',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [fadeSlideInInOutOut]
})
export class ToastComponent {
  @HostBinding('class') class = 'toast-container position-fixed top-0 end-0 p-3';
  @HostBinding('style') style = 'z-index: 1200';

  protected readonly faSquare = faSquare;

  constructor(public toastService: ToastService) {
  }

  trackById(index: number, element: Toast): string {
    return element.id;
  }
}
