import { Routes } from '@angular/router';
import { isNotSignedIn, isSignedIn } from './guards/auth.guard';
import { homeRedirector } from './guards/home.guard';
import { orderedAsyncGuard } from './guards/ordered-async.guard';
import { hasFeature, or } from './guards/feature.guard';
import { inject } from '@angular/core';
import { ErrorOverlayService } from './error-overlay/error-overlay.service';
import { LoadingOverlayService } from './loading-overlay/loading-overlay.service';
import { canDisengage } from './guards/disengage.guard';
import { noError } from './guards/error.guard';

export const APP_ROUTES: Routes = [
  {
    path: 'sign-in',
    loadComponent: () => import('./sign-in/sign-in.component').then(mod => mod.SignInComponent),
    canActivate: [isNotSignedIn]
  },
  {
    path: '',
    loadComponent: () => import('./home/home.component').then(mod => mod.HomeComponent),
    canActivate: [orderedAsyncGuard(isSignedIn, homeRedirector)],
    pathMatch: 'full'
  },
  {
    path: 'welcome',
    loadComponent: () => import('./welcome/welcome.component').then(mod => mod.WelcomeComponent),
    canActivate: [isSignedIn]
  },
  {
    path: 'engage',
    loadComponent: () => import('./engage/engage.component').then(mod => mod.EngageComponent),
    canActivate: [isSignedIn],
    canDeactivate: [canDisengage, noError],
  },
  {
    path: 'preview',
    loadComponent: () => import('./engage/preview.component').then(mod => mod.PreviewComponent),
    canActivate: [orderedAsyncGuard(isSignedIn, hasFeature(or('admin', 'buildswell')))],
    canDeactivate: [ () => {
      inject(LoadingOverlayService).hide();
      inject(ErrorOverlayService).show({ title: 'You cannot move away from preview page'})
      return false;
    } ]
  },
  {
    path: 'explore',
    loadComponent: () => import('./explore/explore.component').then(mod => mod.ExploreComponent),
    canActivate: [isSignedIn],
    canDeactivate: [noError]
  },
  { path: '**', redirectTo: '/' }
];
