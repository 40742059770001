import { Injectable } from '@angular/core';

type Message = { id: string; message: string; }
@Injectable({
  providedIn: 'root'
})
export class TopBarNotificationService {
  messages: Message[] = [];

  constructor() {
    (window as any).notification = this;
  }

  notify(message: string): void {
    if (this.messages.some(m => m.message === message)) {
      return;
    }

    const id = Math.random().toString(36).substring(2, 2 + 10); // 10 char random string;
    this.messages.push({ id, message });
  }

  remove(message: Message) {
    this.messages = this.messages.filter(m => m.id !== message.id);
  }
}
