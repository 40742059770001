<div class="w-100 vh-100 d-flex align-items-center justify-content-center" @fadeInOut *ngIf="shouldShow">
  <svg width="48" height="48" viewBox="0 0 48 48"
       fill="#cccccc"
       xmlns="http://www.w3.org/2000/svg">
    <style>
      .spinner_jCIR {
        animation: spinner_B8Vq .9s linear infinite;
        animation-delay: -.9s;
      }

      .spinner_upm8 {
        animation-delay: -.8s
      }

      .spinner_2eL5 {
        animation-delay: -.7s
      }

      .spinner_Rp9l {
        animation-delay: -.6s
      }

      .spinner_dy3W {
        animation-delay: -.5s
      }

      @keyframes spinner_B8Vq {
        0%, 66.66% {
          animation-timing-function: cubic-bezier(0.36, .61, .3, .98);
          y: 12px;
          height: 24px
        }
        33.33% {
          animation-timing-function: cubic-bezier(0.36, .61, .3, .98);
          y: 2px;
          height: 44px
        }
      }</style>
    <rect class="spinner_jCIR" x="2" y="12" width="5.6" height="24"/>
    <rect class="spinner_jCIR spinner_upm8" x="11.6" y="12" width="5.6" height="24"/>
    <rect class="spinner_jCIR spinner_2eL5" x="21.2" y="12" width="5.6" height="24"/>
    <rect class="spinner_jCIR spinner_Rp9l" x="30.8" y="12" width="5.6" height="24"/>
    <rect class="spinner_jCIR spinner_dy3W" x="40.4" y="12" width="5.6" height="24"/>
  </svg>
</div>
