import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, last, mergeMap, Observable, takeWhile } from 'rxjs';

export type AsyncCanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => Observable<boolean | UrlTree>;

export const orderedAsyncGuard: (...guards: AsyncCanActivateFn[]) => CanActivateFn = (...guards) => {
  return (route, state) => {
    // We need to call guard here otherwise, inject in guard will fail
    return from(guards.map(guard => guard(route, state)))
      .pipe(
        // For each guard, fire canActivate and wait for it
        // to complete.
        mergeMap(result$ => result$, 1),
        // Don't execute the next guard if the current guard's
        // result is not true.
        takeWhile(value => value === true,true),
        last()
    );
  };
}
